<template>
  <!--    RecurringFee Form-->
  <validation-observer
    ref="recurringFeeSettingForm"
    v-slot="{invalid}"
  >
    <lenon-modal
      :title="`${updateRecurringFeeMode?'Update':'Create'} Recurring Fee Setting`"
      :show="recurringFeeSettingModalOpened"
      :show-overlay="deletingRecurringFee"
      size="md"
      @onClose="closeRecurringFeeModal()"
    >
      <error-display :error="error" />
      <b-row class="align-items-start">
        <div class="col-7">
          <lenon-select
            v-model="selectedRecurringFeeId"
            :options="recurringFeeSettings"
            label-name="title"
            value-name="id"
            placeholder="Fee Settings"
            @input="populateRecurringFeeForm"
          />
        </div>
        <div class="col-2">
          <lenon-dropdown
            icon="TrashIcon"
          >
            <b-dropdown-item @click="deleteRecurringFee()">
              Yes
            </b-dropdown-item>
            <b-dropdown-divider />
            <b-dropdown-item>
              No
            </b-dropdown-item>
          </lenon-dropdown>
        </div>
        <div class="col-2">
          <lenon-button
                  variant="flat-danger"
                  icon="XIcon"
                  label=""
                  tool-tip-text="Clear Form"
                  @onClick="resetRecurringFeeForm()"
          />
        </div>
      </b-row>
      <lenon-input
        v-model="recurringFeeSetting.title"
        name="title"
        placeholder="Fee Title, eg. Canteen Fees"
        rules="required"
        label="Fee Title"
      />
      <lenon-input
        v-model="recurringFeeSetting.amount"
        name="amount"
        placeholder="Enter Fee Amount"
        rules="required"
        type="number"
        label="Fee Amount"
      />
      <lenon-multi-select
        v-model="recurringFeeSetting.classes"
        value-name="id"
        label-name="name"
        name="classes"
        placeholder="Select Classes"
        :options="classes"
        rules="required"
        label="Affected Classes"
      />
      <div class="row">
        <div class="col-12 mt-1">
          <b-form-checkbox
            v-model="allClasses"
            class="mb-1"
          >
            Select All Classes
          </b-form-checkbox>
        </div>
      </div>
      <template slot="modal-actions">
        <b-row class="float-right">
          <lenon-button
            variant="flat-danger"
            icon="XIcon"
            class="mr-1"
            label="Cancel"
            @onClick="closeRecurringFeeModal()"
          />
          <lenon-button
            :label="updateRecurringFeeMode?'Update':'Create'"
            :disabled="invalid"
            :loading="recurringFeeSettingLoading"
            loading-text="Loading..."
            @onClick="updateRecurringFeeMode?updateRecurringFee():createRecurringFee()"
          />
        </b-row>
      </template>
    </lenon-modal>
  </validation-observer>
</template>

<script>

import {
  BRow, BDropdownItem, BDropdownDivider, BFormCheckbox,
} from 'bootstrap-vue'
import { ValidationObserver } from 'vee-validate'
import LenonModal from '@/lenon/components/LenonModal.vue'
import LenonInput from '@/lenon/components/LenonInput.vue'
import LenonButton from '@/lenon/components/LenonButton.vue'
import showToast from '@/lenon/mixins/showToast'
import confirm from '@/lenon/mixins/confirm'
import LenonSelect from '@/lenon/components/LenonSelect.vue'
import {
  CREATE_RECURRING_FEE_SETTING_M,
  DELETE_RECURRING_FEE_SETTING_M,
  UPDATE_RECURRING_FEE_SETTING_M,
} from '@/graphql/mutations'
import LenonDropdown from '@/lenon/components/LenonDropdown.vue'
import LenonMultiSelect from '@/lenon/components/LenonMultiSelect.vue'
import ErrorDisplay from '@/lenon/components/ErrorDisplay'
import logData from '@/libs/log'

export default {
  name: 'RecurringFeesSetup',
  components: {
    ErrorDisplay,
    LenonMultiSelect,
    LenonDropdown,
    LenonSelect,
    LenonButton,
    LenonInput,
    LenonModal,
    ValidationObserver,
    BRow,
    BDropdownItem,
    BDropdownDivider,
    BFormCheckbox,
  },
  mixins: [showToast, confirm],
  props: {
    modalOpened: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      error: {},
      allClasses: false,
      deletingRecurringFee: false,
      recurringFeeSettingModalOpened: false,
      updateRecurringFeeMode: false,
      recurringFeeSettingLoading: false,
      selectedRecurringFeeId: null,
      recurringFeeSetting: {
        id: null,
        title: null,
        amount: null,
        classes: [],
      },
    }
  },
  computed: {
    recurringFeeSettings() {
      return this.$store.getters['recurringFeeSettings/recurringFeeSettings']
    },
    classes() {
      return this.$store.getters['termsClasses/classes']
    },
    selectedFee(){
      return this
              .recurringFeeSettings.find(g => +g.id === +this.selectedRecurringFeeId);
    }
  },
  watch: {
    modalOpened(opened) {
      this.recurringFeeSettingModalOpened = opened
    },
    allClasses(checked) {
      if (checked) {
        this.recurringFeeSetting.classes = this.classes
      } else {
        this.recurringFeeSetting.classes = []
      }
    },
  },
  methods: {
    resetRecurringFeeForm() {
      this.updateRecurringFeeMode = false
      this.recurringFeeSetting = {
        id: null,
        title: null,
        amount: null,
        classes: [],
      }
      this.selectedRecurringFeeId = null
      this.$refs.recurringFeeSettingForm.reset()
    },
    populateRecurringFeeForm(rf) {
      if (this.selectedFee) {
        this.updateRecurringFeeMode = true
          this.recurringFeeSetting = {
            ...this.selectedFee,
            classes: this.selectedFee.classes.map(c => this.classes.find(cls => +cls.id === +c)),
          }
          // eslint-disable-next-line no-underscore-dangle
          delete this.recurringFeeSetting.__typename
          delete this.recurringFeeSetting._showDetails
          delete this.recurringFeeSetting.date
        } else {
          this.resetRecurringFeeForm()
        }
    },
    closeRecurringFeeModal() {
      this.recurringFeeSettingModalOpened = false
      this.$emit('modalClosed')
    },
    updateRecurringFee() {
      this.error = {}
      if (!this.recurringFeeSetting.id) {
        return
      }
      this.recurringFeeSettingLoading = true
      this.$apollo.mutate({
        mutation: UPDATE_RECURRING_FEE_SETTING_M,
        variables: {
          input: {
            ...this.recurringFeeSetting,
            classes: this.recurringFeeSetting.classes.map(c => c.id),
            amount: +this.recurringFeeSetting.amount,
          },
        },
      })
        .then(res => {
          this.showSuccess('Updated fee setting successfully')
          this.recurringFeeSettingLoading = false
          this.$store.commit('recurringFeeSettings/updateRecurringFeeSetting', res.data.updateRecurringFeeSetting)
        })
        .catch(err => {
          this.error = err
          logData(err)
          this.showError('Failed to Update Recurring Fee')
          this.recurringFeeSettingLoading = false
        })
    },
    createRecurringFee() {
      this.error = {}
      this.recurringFeeSettingLoading = true
      this.$apollo.mutate({
        mutation: CREATE_RECURRING_FEE_SETTING_M,
        variables: {
          input: {
            ...this.recurringFeeSetting,
            classes: this.recurringFeeSetting.classes.map(c => c.id),
            amount: +this.recurringFeeSetting.amount,
          },
        },
      })
        .then(res => {
          this.showSuccess('Created fee setting successfully')
          this.recurringFeeSettingLoading = false
          this.$store.commit('recurringFeeSettings/addRecurringFeeSetting', res.data.createRecurringFeeSetting)
          this.resetRecurringFeeForm()
        })
        .catch(err => {
          this.error = err
          logData(err)
          this.showError('Failed to create fee setting')
          this.recurringFeeSettingLoading = false
        })
    },
    deleteRecurringFee() {
      if (!this.selectedRecurringFeeId) {
        this.showInfo('Please select a fee setting ')
        return
      }
      this.deletingRecurringFee = true
      this.$apollo.mutate({
        mutation: DELETE_RECURRING_FEE_SETTING_M,
        variables: { id: this.selectedRecurringFeeId },
      })
        .then(() => {
          this.deletingRecurringFee = false
          this.showSuccess('Deleted Successfully')
          this.$store.commit('recurringFeeSettings/removeRecurringFeeSetting', this.selectedRecurringFeeId)
          this.selectedRecurringFeeId = null
          this.resetRecurringFeeForm()
        })
        .catch(() => {
          this.deletingRecurringFee = false
        })
    },
  },
}
</script>
