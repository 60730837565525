<template>
  <div class="academic-years">
    <lenon-list-page
      title="Recurring Fees"
      :columns="columns"
      :rows="recurringFeePayments"
      :table-loading="tableLoading"
      :show-create="false"
      :show-refresh="false"
      :show-search="true"
      :show-details="true"
      search-placeholder="Search"
      :show-profile-photo="true"
    >
      <template slot="table-header">
        <div
          v-responsive="$store.getters['app/smallScreen']"
          class="col-12"
        >
          <b-collapse
            id="recurring"
          >
            <div class="row">
              <div class="col-md-6">
                <lenon-select
                  v-model="selectedAcademicYearId"
                  placeholder="Academic Year"
                  :options="academicYears"
                  label-name="name"
                  value-name="id"
                />
              </div>
              <div class="col-md-6">
                <lenon-select
                  v-model="selectedTermId"
                  placeholder="Select Term"
                  :options="filteredTerms"
                  label-name="name"
                  value-name="ay_term_id"
                />
              </div>
              <div class="col-md-6">
                <lenon-select
                  v-model="selectedClassId"
                  placeholder="Select Class"
                  :options="classes"
                  label-name="name"
                  value-name="id"
                />
              </div>
              <div class="col-md-6">
                <lenon-select
                  v-model="selectedFeeSettingId"
                  placeholder="Fee Setting"
                  :options="filteredFeeSettings"
                  label-name="title"
                  value-name="id"
                />
              </div>
              <div class="col-md-6 mb-1">
                <lenon-button
                  icon="SettingsIcon"
                  label="Recurring Fees"
                  variant="outline-primary"
                  @onClick="showFeeSettingModal()"
                />
              </div>
            </div>
          </b-collapse>
        </div>
        <div class="col-12 mb-1">
          <div class="row">
            <div class="col-md-4">
              <strong>Total Expected:
                <b-badge
                  variant="light-primary"
                  style="font-size: 15px;"
                >
                  <small>{{ $store.getters['auth/currency'] }}</small>{{ totalExpectedAmount | currency }}
                </b-badge>
              </strong>
            </div>
            <div class="col-md-4">
              <strong>Total Collected:
                <b-badge
                  variant="light-success"
                  style="font-size: 15px;"
                >
                  <small>{{ $store.getters['auth/currency'] }}</small>+{{ totalCollectedAmount | currency }}
                </b-badge>
              </strong>
            </div>
            <div class="col-md-4">
              <strong>Total Pending:
                <b-badge
                  variant="light-danger"
                  style="font-size: 15px;"
                >
                  <small>{{ $store.getters['auth/currency'] }}</small>-{{ totalPendingAmount | currency }}
                </b-badge>
              </strong>
            </div>
          </div>
        </div>
      </template>
      <template slot="right-extra-header-actions">
        <div
          v-responsive="$store.getters['app/largeScreen']"
          class="d-flex align-self-center align-items-start"
          style="margin-bottom: -20px;"
        >
          <lenon-select
            v-model="selectedAcademicYearId"
            placeholder="Academic Year"
            :options="academicYears"
            label-name="name"
            value-name="id"
            class="mr-1"
          />
          <lenon-select
            v-model="selectedTermId"
            placeholder="Select Term"
            :options="filteredTerms"
            label-name="name"
            value-name="ay_term_id"
            class="mr-1"
          />
          <lenon-select
            v-model="selectedClassId"
            placeholder="Select Class"
            :options="classes"
            label-name="name"
            value-name="id"
            class="mr-1"
          />
          <lenon-select
            v-model="selectedFeeSettingId"
            placeholder="Fee Setting"
            :options="filteredFeeSettings"
            label-name="title"
            value-name="id"
            class="mr-1"
          />
          <lenon-button
            icon="SettingsIcon"
            label="Recurring Fees"
            tool-tip-text="Manage recurring fee settings (CRUD)"
            @onClick="showFeeSettingModal()"
          />
        </div>
        <div
          v-responsive="$store.getters['app/smallScreen']"
          class="d-flex align-self-center align-items-center"
        >
          <lenon-button
            v-b-toggle.recurring
            icon-only
            icon="MoreVerticalIcon"
            variant="flat-primary"
          />
        </div>
      </template>
      <template #actions="{row}">
        <lenon-button
          icon-only
          variant="flat-primary"
          :icon="row.detailsShowing?'EyeOffIcon':'EyeIcon'"
          @onClick="row.toggleDetails"
        />
      </template>
      <template #total_paid="{row}">
        {{ row.item.total_paid | currency }}

      </template>
      <template #expected_payment="{row}">
        {{ row.item.expected_payment | currency }}

      </template>
      <template #row-details="{row}">
        <b-card v-if="row.item.failed_payments.length>0">
          <b-row
            class="mb-2"
          >
            <b-col
              md="12"
              class="mb-1"
            >
              <strong>Failed Payments (Click to Pay)</strong>
              <b-row class="px-1">
                <div
                  v-for="(payment,index) in row.item.failed_payments"
                  :key="index"
                  style="margin-bottom: 5px; margin-right: 3px;"
                >
                  <b-badge
                    variant="light-success"
                    @click="handlePayment(payment,row.item)"
                  >
                    {{ `${payment.date} (${$store.getters['auth/currency']}` }}{{ payment.amount | currency }})
                  </b-badge>
                </div>
              </b-row>
            </b-col>
          </b-row>
        </b-card>
      </template>
    </lenon-list-page>
    <recurring-fees-setup
      :modal-opened="recurringFeeSetupModalOpened"
      @modalClosed="closeModal('feeSetting')"
    />
  </div>
</template>

<script>
import {
  BBadge, BCol, BRow, BCard, BCollapse, VBToggle
} from 'bootstrap-vue'
import LenonButton from '@/lenon/components/LenonButton.vue'
import showToast from '@/lenon/mixins/showToast'
import { FETCH_RECURRING_FEE_PAYMENTS_Q, FETCH_RECURRING_FEE_SETTINGS_Q } from '@/graphql/queries'
import LenonListPage from '@/lenon/components/LenonListPage.vue'
import confirm from '@/lenon/mixins/confirm'
import RecurringFeesSetup from '@/views/finance/recurring-fees/RecurringFeeSetup.vue'
import LenonSelect from '@/lenon/components/LenonSelect.vue'
import { RECORD_FAILED_PAYMENTS_M } from '@/graphql/mutations'

export default {
  name: 'RecurringFees',
  components: {
    LenonSelect,
    RecurringFeesSetup,
    LenonListPage,
    LenonButton,
    BBadge,
    BRow,
    BCol,
    BCard,
    BCollapse,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  mixins: [showToast, confirm],
  data() {
    return {
      selectedAcademicYearId: null,
      selectedTermId: null,
      recordingPayment: false,
      recurringFeeSetupModalOpened: false,
      initialLoad: false,
      tableLoading: false,
      reducedClasses: {},
      columns: [
        {
          key: 'index',
          label: '#',
        },
        {
          key: 'photo',
          label: 'Photo',
        },
        {
          key: 'full_name',
          label: 'Full Name',
        },
        {
          key: 'total_paid',
          label: `Total Payments(${this.$store.getters['auth/currency']})`,
        },
        {
          key: 'expected_payment',
          label: `Expected Payments(${this.$store.getters['auth/currency']})`,
        },
        {
          key: 'actions',
          label: 'Details',
        },
      ],
      selectedFeeSettingId: null,
      selectedClassId: null,
    }
  },
  computed: {
    filteredTerms() {
      return this.terms.filter(t => this.termIds.includes(t.id))
    },
    termIds() {
      if (this.academicYear) {
        return this.academicYear.terms.map(t => t.term_id)
      }
      return []
    },
    terms() {
      return this.$store.getters['termsClasses/terms']
    },
    academicYears() {
      return this.$store.getters['termsClasses/academicYears']
    },
    academicYear() {
      return this.academicYears.find(ay => ay.id === this.selectedAcademicYearId)
    },
    totalExpectedAmount() {
      return this.recurringFeePayments.reduce((accumulator, currentValue) => accumulator + (+currentValue.expected_payment), 0)
    },
    totalCollectedAmount() {
      return this.recurringFeePayments.reduce((accumulator, currentValue) => accumulator + (+currentValue.total_paid), 0)
    },
    totalPendingAmount() {
      return this.totalExpectedAmount - this.totalCollectedAmount
    },
    previousSetting() {
      return this.$store.getters['recurringFeeSettings/previousSettings']
    },
    filteredFeeSettings() {
      return this.recurringFeeSettings.filter(fs => {
        if (this.selectedClassId) {
          return (fs.classes.includes(this.selectedClassId))
        }
        return false
      })
    },
    recurringFeePayments() {
      return this.$store.getters['recurringFeeSettings/recurringFeePayments']
    },
    recurringFeeSettings() {
      return this.$store.getters['recurringFeeSettings/recurringFeeSettings']
    },
    classes() {
      if (!this.selectedTermId) {
        return []
      }
      const classes = this.$store.getters['termsClasses/classes']
      if (this.selectedTerm.tag_id) {
        return classes.filter(c => this.selectedTerm.classes.includes(c.id))
      }
      return classes
    },
    selectedTerm() {
      return this.terms.find(term => term.ay_term_id === this.selectedTermId)
    },
  },
  watch: {
    selectedClassId() {
      this.selectedFeeSettingId = null
    },
    selectedFeeSettingId(id) {
      if (id) {
        this.$store.commit('recurringFeeSettings/setSettings', {
          selectedFeeSettingId: id,
          selectedClassId: this.selectedClassId,
          selectedTermId: this.selectedTermId,
          selectedAcademicYearId: this.selectedAcademicYearId,
        })
        this.fetchRecurringFeePayments()
      } else {
        this.$store.commit('recurringFeeSettings/setRecurringFeePayments', { recurringFeePayments: [] })
      }
    },
    classes(cls) {
      this.reduceCurrentClasses()
    },
  },
  mounted() {
    this.initialLoad = true
    this.fetchRecurringFees()
    this.setSettings()
    this.reduceCurrentClasses()
  },
  methods: {
    handlePayment(payment, feePayment) {
      this.confirmAction('Are you sure you want to record payment?')
        .then(res => {
          if (res) {
            this.tableLoading = true
            this.$apollo.mutate({
              mutation: RECORD_FAILED_PAYMENTS_M,
              variables: {
                input: {
                  student_id: feePayment.student_id,
                  recurring_fee_class_id: payment.fee_class_id,
                  amount: payment.amount,
                },
              },
            })
              .then(res => {
                this.tableLoading = false
                if (!res.errors) {
                  // eslint-disable-next-line no-param-reassign
                  feePayment.total_paid += payment.amount
                  // eslint-disable-next-line no-param-reassign
                  feePayment.failed_payments = feePayment.failed_payments.filter(fp => fp.fee_class_id !== payment.fee_class_id)
                  this.$store.commit('recurringFeeSettings/updateRecurringFeePayment', feePayment)
                  this.showSuccess('Recorded Successfully')
                } else {
                  this.showError('Something went wrong')
                }
              })
              .catch(err => {
                this.tableLoading = false
                this.showError('Something went wrong')
              })
          }
        })
    },
    setSettings() {
      this.selectedClassId = this.previousSetting.selectedClassId
      this.selectedAcademicYearId = this.previousSetting.selectedAcademicYearId
      setTimeout(() => {
        this.selectedFeeSettingId = this.previousSetting.selectedFeeSettingId
        this.selectedTermId = this.previousSetting.selectedTermId
      }, 200)
    },
    reduceCurrentClasses() {
      this.reducedClasses = this.classes.reduce((cumulatedValue, currentValue) => {
        // eslint-disable-next-line no-param-reassign
        cumulatedValue[+currentValue.id] = currentValue.name
        return cumulatedValue
      }, {})
    },
    showFeeSettingModal() {
      this.recurringFeeSetupModalOpened = true
    },
    closeModal(type) {
      // eslint-disable-next-line default-case
      switch (type) {
        case 'feeSetting':
          this.recurringFeeSetupModalOpened = false
          break
      }
    },
    fetchRecurringFeePayments() {
      if (this.selectedFeeSettingId) {
        this.tableLoading = (this.initialLoad ? this.recurringFeePayments.length < 1 : true)
        this.initialLoad = false
        this.$apollo.query({
          query: FETCH_RECURRING_FEE_PAYMENTS_Q,
          variables: {
            fee_setting_id: this.selectedFeeSettingId,
            class_id: this.selectedClassId,
            academic_year_id: this.selectedAcademicYearId,
            term_id: this.selectedTermId,
          },
        })
          .then(res => {
            this.tableLoading = false
            if (res.data) {
              this.$store.commit('recurringFeeSettings/setRecurringFeePayments', res.data)
            }
          })
          .catch(err => {
            this.tableLoading = false
            this.showError('Something went wrong, please try again')
          })
      }
    },
    fetchRecurringFees() {
      this.$apollo.query({
        query: FETCH_RECURRING_FEE_SETTINGS_Q,
      })
        .then(res => {
          this.tableLoading = false
          if (res.data) {
            this.$store.commit('recurringFeeSettings/setRecurringFeeSettings', res.data)
          }
        })
        .catch(err => {
          this.tableLoading = false
          this.showError('Something went wrong, please try again')
        })
    },
  },
}
</script>
